/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FanManagementConfigModel { 
    apiUrl?: string | null;
    ticketShopURL?: string | null;
    defaultLanguage?: string | null;
    defaultLocale?: string | null;
    formatDateTime?: string | null;
    formatDate?: string | null;
    formatTime?: string | null;
    timeZone?: string | null;
    PhoneValidation?: string | null;
}

