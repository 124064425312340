import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApplicationModulesService } from './api/applicationModules.service';
import { AuthenticationService } from './api/authentication.service';
import { CacheService } from './api/cache.service';
import { ConfigService } from './api/config.service';
import { ContactService } from './api/contact.service';
import { CrossSellProductService } from './api/crossSellProduct.service';
import { CustomerMembershipService } from './api/customerMembership.service';
import { EventService } from './api/event.service';
import { HealthService } from './api/health.service';
import { LanguageService } from './api/language.service';
import { MemberService } from './api/member.service';
import { OrderService } from './api/order.service';
import { OrderManagementService } from './api/orderManagement.service';
import { ResellerService } from './api/reseller.service';
import { SeasonService } from './api/season.service';
import { StatisticsService } from './api/statistics.service';
import { TicketService } from './api/ticket.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
