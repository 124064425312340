/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FanOrderPaymentHistoryModel { 
    Description?: string | null;
    Amount?: number | null;
    Type?: string | null;
    ReceivedTimeStamp?: Date | null;
    Handled?: boolean;
    PaymentStatus?: number;
    PaymentReference?: string | null;
    PaymentMethodName?: string | null;
    PaymentTypeId?: number | null;
}

