export * from './applicationModules.service';
import { ApplicationModulesService } from './applicationModules.service';
export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './cache.service';
import { CacheService } from './cache.service';
export * from './config.service';
import { ConfigService } from './config.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './crossSellProduct.service';
import { CrossSellProductService } from './crossSellProduct.service';
export * from './customerMembership.service';
import { CustomerMembershipService } from './customerMembership.service';
export * from './event.service';
import { EventService } from './event.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './language.service';
import { LanguageService } from './language.service';
export * from './member.service';
import { MemberService } from './member.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './orderManagement.service';
import { OrderManagementService } from './orderManagement.service';
export * from './reseller.service';
import { ResellerService } from './reseller.service';
export * from './season.service';
import { SeasonService } from './season.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './ticket.service';
import { TicketService } from './ticket.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [ApplicationModulesService, AuthenticationService, CacheService, ConfigService, ContactService, CrossSellProductService, CustomerMembershipService, EventService, HealthService, LanguageService, MemberService, OrderService, OrderManagementService, ResellerService, SeasonService, StatisticsService, TicketService, UserService];
