/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TurnOverPerPeriod { 
    Start?: Date;
    End?: Date;
    Turnover?: number;
    SoldTicketAmount?: number;
    SoldMatchDayPasses?: number;
    OrderCount?: number;
    AverageOrderValue?: number;
    ActiveMembers?: number;
    AccountActivations?: number;
    AccountPWDResets?: number;
    AccountLogins?: number;
}

