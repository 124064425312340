/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CustomerMembershipStatusEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const CustomerMembershipStatusEnum = {
    NUMBER_1: 1 as CustomerMembershipStatusEnum,
    NUMBER_2: 2 as CustomerMembershipStatusEnum,
    NUMBER_3: 3 as CustomerMembershipStatusEnum,
    NUMBER_4: 4 as CustomerMembershipStatusEnum,
    NUMBER_5: 5 as CustomerMembershipStatusEnum,
    NUMBER_6: 6 as CustomerMembershipStatusEnum,
    NUMBER_7: 7 as CustomerMembershipStatusEnum
};

