/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MyTicketStatusEnum = 'Bought' | 'MyClaimedTicket' | 'IdentificationRequired' | 'AssignmentRequired' | 'InvitationPending' | 'InvitationRevoked' | 'Assigned' | 'InvitationDeclined' | 'SecondaryMarketOnResale' | 'SecondaryMarketSold';

export const MyTicketStatusEnum = {
    Bought: 'Bought' as MyTicketStatusEnum,
    MyClaimedTicket: 'MyClaimedTicket' as MyTicketStatusEnum,
    IdentificationRequired: 'IdentificationRequired' as MyTicketStatusEnum,
    AssignmentRequired: 'AssignmentRequired' as MyTicketStatusEnum,
    InvitationPending: 'InvitationPending' as MyTicketStatusEnum,
    InvitationRevoked: 'InvitationRevoked' as MyTicketStatusEnum,
    Assigned: 'Assigned' as MyTicketStatusEnum,
    InvitationDeclined: 'InvitationDeclined' as MyTicketStatusEnum,
    SecondaryMarketOnResale: 'SecondaryMarketOnResale' as MyTicketStatusEnum,
    SecondaryMarketSold: 'SecondaryMarketSold' as MyTicketStatusEnum
};

