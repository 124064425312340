/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimeSpan { 
    Ticks?: number;
    Days?: number;
    Hours?: number;
    Milliseconds?: number;
    Microseconds?: number;
    readonly Nanoseconds?: number;
    Minutes?: number;
    Seconds?: number;
    readonly TotalDays?: number;
    readonly TotalHours?: number;
    readonly TotalMilliseconds?: number;
    readonly TotalMicroseconds?: number;
    readonly TotalNanoseconds?: number;
    readonly TotalMinutes?: number;
    readonly TotalSeconds?: number;
}

