/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EventPolicyEnum = 1 | 2 | 3;

export const EventPolicyEnum = {
    NUMBER_1: 1 as EventPolicyEnum,
    NUMBER_2: 2 as EventPolicyEnum,
    NUMBER_3: 3 as EventPolicyEnum
};

